import { useAccount } from 'wagmi';
import { ConnectButton } from '../connect-button';
import { Button } from '../ui/button';
import { JazzIcon } from '@/assets/Jazzicon';
import { useAccountModal } from '@rainbow-me/rainbowkit';

export const UserPortfolio = () => {
  const { address } = useAccount();
  const {openAccountModal} = useAccountModal()

  if (!address)
    return <ConnectButton className="bg-sgs-gradient" variant="secondary" />;

  const truncatedAddress = `${address.substring(0, 6)}...${address.substring(address.length - 4)}`;

  return (
    <Button onClick={openAccountModal} className="bg-sgs-gradient pointer-events-auto" variant="secondary">
      <JazzIcon diameter={20} address={address} />
      <span className="hidden sm:block">{truncatedAddress}</span>
    </Button>
  );
};