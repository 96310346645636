"use client";
import { FC, ReactNode } from "react";
import { useEnabledCookies } from "@/hooks/useEnabledCookies";
import { getWagmiConfig, getWagmiInitialState } from "@/config";
import { WagmiProvider as _WagmiProvider } from "wagmi";
import { darkTheme, RainbowKitProvider } from "@rainbow-me/rainbowkit";
import { WagmiStoreVersionCheck } from "@/components/wagmi/wagmi-store-version-check";
import { WagmiSentry } from "@/components/wagmi/wagmi-sentry";

export const WagmiProvider: FC<{
  children: ReactNode;
  cookie?: string | null;
}> = ({ children, cookie }) => {
  const enabledCookies = useEnabledCookies();

  const functionalCookiesEnabled = !!enabledCookies?.has("functional");
  const initialState = getWagmiInitialState(cookie, functionalCookiesEnabled);
  return (
    <_WagmiProvider
      config={getWagmiConfig({
        useCookies: functionalCookiesEnabled,
      })}
      initialState={initialState}
    >
      <RainbowKitProvider showRecentTransactions  theme={darkTheme()} modalSize="compact">
        <WagmiSentry>
          <WagmiStoreVersionCheck>{children}</WagmiStoreVersionCheck>
        </WagmiSentry>
      </RainbowKitProvider>
    </_WagmiProvider>
  );
};
