'use client';
import '@rainbow-me/rainbowkit/styles.css';
import { Toaster } from "@/components/ui/toaster"
import { QueryClientProvider } from '@/providers/query-client-provider';
import { WagmiProvider } from '@/providers/wagmi-provider';
import { SwapProvider } from '@/providers/swap-provider';
import { StoreProvider } from '@/providers/store-provider';

export function Providers({
  children,
  cookie,
}: {
  children: React.ReactNode;
  cookie: string | null;
}) {
  return (
    <StoreProvider>
      <QueryClientProvider>
        <WagmiProvider cookie={cookie}>
          <SwapProvider>{children}</SwapProvider>
          <Toaster />
        </WagmiProvider>
      </QueryClientProvider>
    </StoreProvider>
  );
}
