import { FC, Suspense, useState } from 'react';
import { Popover, PopoverContent, PopoverTrigger } from '../ui/popover';
import { NetworkIcon } from '@/assets';
import { Chain, publicChains } from '@/config/publicWagmiConfig';
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from '../ui/command';
import { TailSpin } from 'react-loading-icons';
import { twMerge } from 'tailwind-merge';
type NetworkSelectorOnSelectCallback = (
  chainId: number,
  close: () => void
) => void;
const defaultClass = "bg-sgs-gradient cursor-pointer whitespace-nowrap inline-flex gap-2 items-center justify-center font-medium disabled:opacity-50 disabled:pointer-events-none ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 ring-blue bg-secondary hover:opacity-65 focus:bg-accent min-h-[40px] h-[40px] py-2 px-4 text-sm rounded-xl"
export const NetworkSelector: FC<{
  selected: number;
  onSelect: NetworkSelectorOnSelectCallback;
  isPending?: boolean;
  className?: string
  showName?: boolean
}> = ({ selected, onSelect, isPending , className = defaultClass , showName}) => {
  const [open, setOpen] = useState(false);
  const [pendingId, setPendingId] = useState<null | number>(null);
  return (
    <Popover   modal={true} open={open} onOpenChange={setOpen}>
      <PopoverTrigger className={className}>
        <Suspense fallback={null}>
          <NetworkIcon chainId={selected} width={24} height={24} />
          <div className={twMerge("xl:block", showName ? 'block': 'hidden')}>{Chain[selected]}</div>
          
        </Suspense>
      </PopoverTrigger>
      <PopoverContent align={showName ? 'end' : 'center'}  className="!w-60 !p-0 !overflow-x-hidden !overflow-y-scroll scroll bg-cardBg border-cardBorder">
        <Command className="bg-cardBg  text-white">
          <CommandInput
            className="border-b-cardBorder"
            placeholder="Search network"
          />
          <CommandList>
            <CommandEmpty>No network found.</CommandEmpty>
            <CommandGroup className="text-white font-medium space-y-2">
              {publicChains?.map((c) => {
                return (
                  <CommandItem
                    className="cursor-pointer data-[selected='true']:bg-[#100c18] data-[selected='true']:text-white font-medium rounded-md"
                    value={`${c.name}__${c.id}`}
                    key={c.id}
                    onSelect={(value) => {
                        setPendingId(+value.split('__')[1])
                        onSelect(+value.split('__')[1], () => setOpen(false))
                    }}>
                    <div className="flex justify-between w-full">
                      <div className="flex items-center gap-2">
                        <NetworkIcon chainId={c.id} width={22} height={22} />
                        <div className='text-white'>{Chain[c?.id]}</div>
                      </div>
                      {isPending && c.id === pendingId && <TailSpin className="w-4 h-4" />}
                    </div>
                  </CommandItem>
                );
              })}
            </CommandGroup>
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  );
};
