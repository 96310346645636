import { cookieToInitialState } from "wagmi"
import { createProductionConfig } from "./config"
import { PublicWagmiConfig } from "./publicWagmiConfig"


const createWagmiConfig = ({ useCookies }: { useCookies: boolean }) => {
    const config = (() => {
      
      return createProductionConfig({ useCookies })
    })()
  
    return config
  }

let wagmiConfigSingleton: PublicWagmiConfig | undefined = undefined
export const getWagmiConfig = ({ useCookies }: { useCookies: boolean }) => {
  if (typeof window === 'undefined') {
    return createWagmiConfig({ useCookies })
  }

  if (!wagmiConfigSingleton) {
    wagmiConfigSingleton = createWagmiConfig({ useCookies })
  }

  return wagmiConfigSingleton
}

export const getWagmiInitialState = (
  cookieHeaders: string | null | undefined,
  functionalCookiesEnabled: boolean,
) => {
  const initialState = cookieToInitialState(
    getWagmiConfig({ useCookies: functionalCookiesEnabled }),
    cookieHeaders,
  )
  return initialState
}

