import { FC, useCallback } from 'react';
import { ProviderRpcError, UserRejectedRequestError } from 'viem';
import { useChainId, useSwitchChain } from 'wagmi';
import { NetworkSelector } from '../network';
import { useSwapProvider } from '@/providers/swap-provider';

export const HeaderNetworkSelector: FC<{
  selectedNetwork?: number;
  onChange?(chainId: number): void;
}> = ({ selectedNetwork, onChange }) => {
  const { switchChainAsync, isPending } = useSwitchChain();
  const { mutate } = useSwapProvider();
  const chainId = useChainId();

  const onSwitchNetwork = useCallback(
    async (el: number, close: VoidFunction) => {
      try {
        if (switchChainAsync && chainId !== el) {
          await switchChainAsync({ chainId: el });
          mutate.setChain(el);
        }

        if (selectedNetwork !== el && onChange) {
          onChange(el);
        }

        close();
      } catch (e) {
        console.error(`Failed to switch network: ${e}`);
        if (e instanceof UserRejectedRequestError) return;
        if (e instanceof ProviderRpcError) {
          console.log(e.message, true);
        }
      }
    },
    [chainId, mutate, onChange, selectedNetwork, switchChainAsync]
  );
  return (
    <NetworkSelector
      isPending={isPending}
      selected={chainId}
      onSelect={onSwitchNetwork}
    />
  );
};
